import * as React from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
import { useState, useEffect } from "react";
import { get, post } from './requests';
import { errorText, ErrorResponse } from './error';
import { useDropzone } from 'react-dropzone';
import { rankString } from "./misc";


export function SGFs():JSX.Element {
    return (
        <section>
            <GameTable />
        </section>
    );
}

interface VKPlayerSummary {
    username: string;
    rank: string;
    ai_match_rate: number;
    score_delta_mean: number;
    score_delta_stdev: number;
}

interface VKSummary {
    black: VKPlayerSummary;
    white: VKPlayerSummary;
}

interface Record {
    id: number;
    uuid: string;
    name: string;
    game_date: string;
    created: string;
    filename: string;
    vk: VKSummary | null;
}

export function GameTable():JSX.Element {
    const PAGE_SIZE = 10;

    let [page, setPage] = useState(1);
    let [rows, setRows] = useState([] as Array<Record>);
    let [loading, setLoading] = useState(false);
    let [err, setErr] = useState(null as ErrorResponse | null);


    function onDrop(files:Array<File>) {
        console.log('drop files', files);
        if (files.length === 0) {
            alert("No suitable files");
            return;
        }
        try {
            Promise.all(files.map(file => post("record/upload", file)))
            .then((res:any) => {
                console.log(res);
                if (page !== 1) {
                    setPage(1);
                } else {
                    reload();
                }
            })
            .catch((err:any) => {
                setErr(err);
            })
        } catch (err) {
            console.log(err);
        }
    }


    const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
        accept: [
            'application/x-go-sgf',
            '.sgf',
        ],
        multiple: true,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop
    })


    function reload():void {
        setLoading(true);
        get(`record/sgf?page=${page}`)
        .then((results) => {
            setLoading(false);
            console.log(results);
            setRows(results.results);
            setErr(null);
        })
        .catch((err) => {
            console.error(err);
            setLoading(false);
            setErr(err);
        });
    }

    useEffect(reload, [page])


    return (
        <section>
            <div id='GameTable'>
                { err ? (<div className='error'>{errorText(err)}</div>) : null }

                <div>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>Upload Date</th>
                                <th rowSpan={2}>Game Date</th>
                                <th rowSpan={2}>Game</th>
                                <th colSpan={4}>White</th>
                                <th colSpan={4}>Black</th>
                            </tr>
                            <tr>
                                <th>Player</th>
                                <th>Match</th>
                                <th style={{'textDecoration': 'overline'}}>&Delta;</th>
                                <th>&sigma;</th>

                                <th>Player</th>
                                <th>Match</th>
                                <th style={{'textDecoration': 'overline'}}>&Delta;</th>
                                <th>&sigma;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rows.map((row) => (
                                    <tr key={row.id}>
                                        <td className='date'>{moment(row.created).format('L')}</td>
                                        <td className='date'>{moment(row.game_date).format('L')}</td>
                                        <td className='game-name'><Link to={`/record/${row.uuid}`}>{row.name || "Game"}</Link></td>

                                        <td className='player'>{row.vk?.white?.username} {rankString(row.vk?.white?.rank)}</td>
                                        <td className='stat'>{((row.vk?.white.ai_match_rate || 0) * 100.0).toFixed(1)}%</td>
                                        <td className='stat'>{(row.vk?.white.score_delta_mean || 0).toFixed(1)}</td>
                                        <td className='stat'>{(row.vk?.white.score_delta_stdev || 0).toFixed(1)}</td>

                                        <td className='player'>{row.vk?.black?.username} {rankString(row.vk?.black?.rank)}</td>
                                        <td className='stat'>{((row.vk?.black.ai_match_rate || 0) * 100.0).toFixed(1)}%</td>
                                        <td className='stat'>{(row.vk?.black.score_delta_mean || 0).toFixed(1)}</td>
                                        <td className='stat'>{(row.vk?.black.score_delta_stdev || 0).toFixed(1)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {page <= 1 && rows.length === 0 ?  <div className='center'>No SGFs uploaded yet</div> : null }
                </div>

                <p className='buttons'>
                    <span>{ !loading && page > 1 ? <button onClick={() => setPage(page-1)}>&lt; Previous</button> : null }</span>
                    <span className={'upload' + (isDragActive ? ' active' : '')} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <button type="button" onClick={open}>
                          Upload SGF Files
                        </button>
                    </span>
                    <span>{ !loading && rows.length === PAGE_SIZE ? <button onClick={() => setPage(page+1)}>Next &gt;</button> : null }</span>
                </p>
            </div>
        </section>
    );

}

