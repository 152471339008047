export interface ErrorResponse {
    errcode?: string;
    error?: string;

    status?: number;
    responseJSON?: ErrorResponse;
    statusText?: string;
}


export function errorText(err: ErrorResponse):string {
    if (err.responseJSON) {
        return errorText(err.responseJSON);
    }

    if (err.errcode) {
        switch (err.errcode) {
            case 'authentication_error':
                return 'Incorrect username or password';
        }

        return err.errcode + (err.error || "");
    }

    if (err.error && typeof(err.error) !== "function") {
        return err.error;
    }

    if (err.statusText) {
        return `${err.status || ""}: ${err.statusText}`;
    }

        console.log("<errr>");
    return "<error>";
}
