import { TypedEventEmitter } from './TypedEventEmitter';

interface Events {
    [name:string]: any;
}

let defaults:{[id: string]: any} = {};
let store:{[id: string]: any} = {};
let event_emitter = new TypedEventEmitter<Events>();

export function setWithoutEmit(key: string, value: any | undefined): any {
    if (value === undefined) {
        remove(key);
        return value;
    }

    store[key] = value;
    try {
        localStorage.setItem(`kgsai.${key}`, JSON.stringify(value));
    } catch (e) {
        console.warn(`Failed to save setting kgsai.${key}, LocalStorage is probably disabled. If you are using Safari, the most likely cause of this is being in Private Browsing Mode.`);
    }

    return value;
}

export function set(key: string, value: any | undefined): any {
    setWithoutEmit(key, value);
    event_emitter.emit(key, value);
    return value;
}

export function setDefault(key: string, value: any): any {
    defaults[key] = value;
    if (!(key in store)) {
        event_emitter.emit(key, value);
    }
    return value;
}

export function remove(key: string): any {
    event_emitter.emit(key, defaults[key]);

    try {
        localStorage.removeItem(`kgsai.${key}`);
    } catch (e) {
        console.error(e);
    }
    if (key in store) {
        let val = store[key];
        delete store[key];
        return val;
    }
}

export function removePrefix(key_prefix: string): any {
    let hits:{[id: string]: any} = {};

    Object.keys(store).map((key) => {
        if (key.indexOf(key_prefix) === 0) {
            hits[key] = key;
        }
    });

    for (let key in hits) {
        localStorage.removeItem(`kgsai.${key}`);
        delete store[key];
        event_emitter.emit(key, defaults[key]);
    }
}

export function removeAll(): void {
    let keys = [];
    for (let key in store) {
        keys.push(key);
    }
    for (let key of keys) {
        try {
            remove(key);
        } catch (e) {
            console.error(e);
        }
    }
}

export function get(key: "editing", default_value?: boolean): boolean;
export function get(key: string, default_value?: any): any | undefined;
export function get(key: string, default_value?: any): any | undefined {
    if (key in store) {
        return store[key];
    }
    if (key in defaults) {
        return defaults[key];
    }
    return default_value;
}

export function watch(key: string, cb: (value:any) => void, call_on_undefined?: boolean, dont_call_immediately?: boolean): void {
    event_emitter.on(key, cb);

    let val = get(key);
    if (!dont_call_immediately && (val != undefined || call_on_undefined)) {
        cb(val);
    }
}

export function unwatch(key: string, cb: (value:any) => void): void {
    event_emitter.off(key, cb);
}

export function dump(key_prefix: string = "", strip_prefix?: boolean) {
    if (!key_prefix) {
        key_prefix = "";
    }
    let ret: {[id: string]: any} = {};
    let data = Object.assign({}, defaults, store);
    let keys = Object.keys(data);

    keys.sort().map((key) => {
        if (key.indexOf(key_prefix) === 0) {
            let k = strip_prefix ? key.substr(key_prefix.length) : key;
            ret[k] = {"union": data[key], value: store[key], "default": defaults[key]};
        }
    });
    console.table(ret);
}


try {
    for (let i = 0; i < localStorage.length; ++i) {
        let key = localStorage.key(i) ?? '';
        if (key.indexOf("kgsai.") === 0) {
            key = key.substr(6);
            try {
                let item = localStorage.getItem(`kgsai.${key}`) ?? '';
                store[key] = JSON.parse(item);
            } catch (e) {
                localStorage.removeItem(`kgsai.${key}`);
                console.error(`Data storage system failed to load ${key}. Value was: `, typeof(localStorage.getItem(`kgsai.${key}`)), localStorage.getItem(`kgsai.${key}`));
                console.error(e);
            }
        }
    }
} catch (e) {
    console.error(e);
}

