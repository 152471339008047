import * as React from "react";
import { useState, useEffect, ChangeEvent, KeyboardEvent, FormEvent } from "react";
import { put } from './requests';
import * as data from './data';
import { errorText, ErrorResponse } from './error';
import { KGSAILogoFull } from './Logo';

export function Login():JSX.Element {
    let [username, _setUsername] = useState('');
    let [password, _setPassword] = useState('');
    let [err, _setErr] = useState(null as ErrorResponse | null);
    let [loggingIn, setLoggingIn] = useState(false);

    function setUsername(ev:ChangeEvent<HTMLInputElement>) {
        let username = ev.target.value;
        _setErr(null);
        _setUsername(username);
    }

    function setPassword(ev:ChangeEvent<HTMLInputElement>) {
        let password = ev.target.value;
        _setErr(null);
        _setPassword(password);
    }

    useEffect(() => {
        document.getElementById('username')?.focus();
    }, []);

    function advanceOrSubmit() {
        if (username.trim() === "") {
            document.getElementById('username')?.focus();
            return;
        }
        if (password.trim() === "") {
            document.getElementById('password')?.focus();
            return;
        }

        if (loggingIn) {
            return;
        }

        setLoggingIn(true);

        put('auth/login', {username, password})
        .then(res => {
            let user = res;
            setLoggingIn(false);
            console.log(user);
            data.set('user', res);
            window.location.reload();
        })
        .catch(err => {
            setLoggingIn(false);
            console.log(err);
            _setErr(err);
        });


    }

    function keyPress(ev:KeyboardEvent<HTMLInputElement>) {
        if (ev.type === "keypress") {
            if (ev.charCode === 13) {
                ev.preventDefault();
                advanceOrSubmit();
            }
        }
    }

    function onSubmit(ev:FormEvent<HTMLFormElement>) {
        advanceOrSubmit();
        ev.preventDefault();
    }

    return (
        <div id='Login'>
            <section id='LoginBoxContainer'>
                <KGSAILogoFull />
                <form id='LoginBox' onSubmit={onSubmit}>
                    <input id='username' type='text'
                        value={username}
                        onChange={setUsername}
                        onKeyPress={keyPress}
                        placeholder='Username'
                        disabled={loggingIn}
                    />
                    <input id='password' type='password'
                        value={password}
                        onChange={setPassword}
                        placeholder='Password'
                        onKeyPress={keyPress}
                        disabled={loggingIn}
                    />
                    { err ? (<span className='error'>{errorText(err)}</span>) : null }
                    <button
                        onClick={advanceOrSubmit}
                        disabled={loggingIn}
                        >Login</button>
                </form>
            </section>
        </div>
    );
}

export function logout() {
    data.remove('user');
    window.location.reload();
}
