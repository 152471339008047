import {EventEmitter} from 'eventemitter3';

export class TypedEventEmitter<T> {
    private emitter = new EventEmitter();

    addListener<K extends Extract<keyof T, string>>(event: K, listener: (arg?: T[K]) => any): this {
        this.emitter.addListener(event, listener);
        return this;
    }
    on<K extends Extract<keyof T, string>>(event: K, listener: (arg?: T[K]) => any): this {
        this.emitter.on(event, listener);
        return this;
    }
    off<K extends Extract<keyof T, string>>(event: K, listener: (arg?: T[K]) => any): this {
        this.emitter.off(event, listener);
        return this;
    }
    once<K extends Extract<keyof T, string>>(event: K, listener: (arg?: T[K]) => any): this {
        this.emitter.once(event, listener);
        return this;
    }
    removeListener<K extends Extract<keyof T, string>>(event: K, listener: (arg?: T[K]) => any): this {
        this.emitter.removeListener(event, listener);
        return this;
    }
    removeAllListeners<K extends Extract<keyof T, string>>(event?: K): this {
        this.emitter.removeAllListeners(event);
        return this;
    }
    listeners<K extends Extract<keyof T, string>>(event: K): ((arg: T[K]) => any)[] {
        return this.emitter.listeners(event);
    }
    emit<K extends Extract<keyof T, string>>(event: K, arg?: T[K]): boolean {
        return this.emitter.emit(event, arg);
    }
}
