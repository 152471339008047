import * as React from "react";
import * as data from './data';
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { browserHistory } from './kgsaiHistory';

import { Login, logout } from "./Login";
import { Home } from "./Home";
import { KGSAILogo } from './Logo';
import { SGFs } from "./SGFs";
import { Account } from "./Account";
import { Record } from "./Record";


export function PageNotFound():JSX.Element {
    return (
        <div style={{display: "flex", flex: "1", alignItems: "center", justifyContent: "center"}}>Page not found</div>
    );
}

export function routes():JSX.Element {
    let user = data.get('user');
    (window as any)['user'] = user;

    if (!user) {
        return (
            <Router history={browserHistory}>
                <Login />
            </Router>
        );
    }

    return (
        <Router history={browserHistory}>
            <Nav />
            <div>
                <Switch>
                    <Route path="/login" render={() => <Redirect to={`/`}/>} />
                    <Route path="/sgfs" component={SGFs} exact />
                    <Route path="/account" component={Account} exact />
                    <Route path="/record/:uuid" component={Record} exact />
                    <Route path="/" component={Home} exact />
                    <Route path="/*" component={PageNotFound} />
                </Switch>
            </div>
        </Router>
    );
}




export function Nav():JSX.Element {
    let [path, setPath] = useState(window.location.pathname);
    let active = '';

    useEffect(() => {
        browserHistory.listen(location => {
            setPath(location.pathname);
        });
    }, []);


    if (path.indexOf('sgfs') > 0) {
        active = 'sgf';
    }
    else if (path.indexOf('account') > 0) {
        active ='account';
    }

    return (
        <header>
            <nav>
                <Link to='/'><KGSAILogo /></Link>
                <ul>
                    <li className={active === 'sgf' ? 'active' : ''}><Link to='/sgfs'>SGFs</Link></li>
                    <li className={active === 'account' ? 'active' : ''}><Link to='/account'>Account</Link>
                        <ul>
                            <li><Link to='/account'>Settings</Link></li>
                            <li><a onClick={logout}>Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
